import React from "react";
import styled from "styled-components";

import theme from "theme";
import Typography from "Typography";

const LinkTypography = styled(Typography)`
  position: relative;
  display: inline-block;

  a {
    ${"" /* color: ${theme.color.black}; */}
    ${"" /* color: ${theme.color.beige.light}; */}
    ${"" /* color: ${theme.color.beige.primary}; */}
    ${"" /* color: ${theme.color.white}; */}
    ${"" /* text-shadow: -1px 1px 0px #000; */}
    color: inherit;
    ${"" /* color: #000; */}
    font-size: ${theme.font.display6};
    font-weight: bold;
    letter-spacing: 0.8px;
    ${"" /* line-height: 1rem; */}
    text-transform: uppercase;
    border-bottom: none;
    border-color: none;
    position: relative;
    text-align: inherit;

    &::after{
      width: 0rem;
      display: block;
      content: "";
      position: absolute;
      bottom: -5px;
      border-bottom-width: 2px;
      border-bottom-style: solid;
      border-bottom-color: inherit;
      transition: width 0.3s linear;
    }

    &:hover {
      ${"" /* color: ${theme.color.black}; */}
      color: inherit;
      border: none;
      ${"" /* border-bottom-color: ${theme.color.black}; */}
      border-color: inherit;
      padding: 0;

      &::after {
        border-color: inherit;
        width: 100%;
      }
    }
  }
`;

export default class extends React.Component {
  render() {
    return (
      <LinkTypography {...this.props}>{this.props.children}</LinkTypography>
    );
  }
}
