import React from 'react'

import getFetchJson from 'Response'

import SiteTitle from 'SiteTitle'
import Menu from 'Menu'
import Header from 'Header'
import Builder from 'Builder'
import CarouselMultipleSlides from 'CarouselMultipleSlides'
import Footer from 'Footer'

class BlogPost extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      error: null,
      isLoaded: false,
      data: [],
      dataGlobal: [],
    }
  }

  getJson = (slug) => {

    const data = getFetchJson(slug, 'Post')
    const globalData = getFetchJson(null, 'Global')

    return Promise.all([data, globalData])
      .then((json) => {
        this.setState({
          data: json[0],
          dataGlobal: json[1],
          isLoaded: true,
        })
      })

  }

  componentDidMount() {

    const slug = this.props.match.params.id
    this.getJson(slug)

  }

  componentDidUpdate(nextProps) {

    if (this.props.location !== nextProps.location) {
      window.scrollTo(0, 0)
    }

  }

  render() {

    let {
      error,
      isLoaded,
      data,
      dataGlobal,
    } = this.state

    if (error) {
      return <div>Error: {error.message}</div>
    } else if (!isLoaded) {
      return null
    } else {

      return (

        <main>
          <SiteTitle>{data.title} &mdash; Long Play</SiteTitle>
          <Menu data={dataGlobal.menus} />
          <Header data={data} />
          <Builder data={data} />
          <CarouselMultipleSlides title={'More of our work'} data={dataGlobal.options} />
          <Footer />
        </main>

      )

    }

  }

}

export default BlogPost
