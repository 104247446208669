import React from 'react'

import SiteTitle from 'SiteTitle'
import Menu from 'Menu'
import Header from 'Header'
import Builder from 'Builder'
import LocationsPanel from 'LocationsPanel'
import ContactForm from 'ContactForm'
import LogoGridPanel from 'LogoGridPanel'
import Footer from 'Footer'

import getData from 'Data'

class Contact extends React.Component {

  render() {

    const {
      data,
      dataGlobal,
    } = this.props

    const emailAuth = dataGlobal.options.contact_form_email_aws
    const emailTo = dataGlobal.options.contact_form_email_to

    const userContactDetails = {
      emailAuth: emailAuth,
      emailTo: emailTo,
      emailFrom: '',
      name: '',
      subject: '',
      message: '',
    }

    return (
      <main>
        <SiteTitle>Long Play</SiteTitle>
        <Menu data={dataGlobal.menus} transparent border />
        <Header data={data} />
        <Builder data={data} />
        <LocationsPanel data={data} />
        <ContactForm user={userContactDetails} data={dataGlobal}/>
        <LogoGridPanel data={dataGlobal.options} />
        <Footer />
      </main>
    );

  }

}

export default getData('contact', 'Contact')(Contact)
